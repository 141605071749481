import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-relacao-de-sentido',
  templateUrl: './relacao-de-sentido.component.html',
  styleUrls: ['./relacao-de-sentido.component.css']
})
export class RelacaoDeSentidoComponent implements OnInit {
	public relacaoDeSentidoSelecionada = null;
	public listaDeRelacoesDeSentido = [];
	// [
	// 	{
	// 		id: "1",
	// 		relacaoDeSentido: "Adição",
	// 		explicacao: "Explicação Adição. Explicação Adição. Explicação Adição. Explicação Adição. Explicação Adição. Explicação Adição. Explicação Adição.",
	// 		listaDePalavras: [
	// 			{
	// 				id: "1",
	// 				palavra: "asdf",
	// 				fonte: "asdva asda asd asdfas",
	// 				tema: "asdf asdv asdf"
	// 			},
	// 			{
	// 				id: "2",
	// 				palavra: "kjoijoi",
	// 				fonte: "fghjklsd yryjn gji noj gowj glmsglj",
	// 				tema: "fjekjoi reoi ojmo"
	// 			}
	// 		]
	// 	}
	// ];
	public listaDeRelacoesDeSentidoFiltrada = this.listaDeRelacoesDeSentido;
	
	constructor(private http: HttpClient){
		console.log("asdf");
		this.http.get(environment.httpUrlGetRelacoesDeSentido, {}).subscribe(
            result => this.callback(result),
            err => this.httpError(err)
        );
	}
	
	callback(response){
        console.log(response);
		this.listaDeRelacoesDeSentido = response;
		this.listaDeRelacoesDeSentidoFiltrada = this.listaDeRelacoesDeSentido;
    }
	
	httpError(err){
		console.log('HttpError => ', err);
    }
	
	filtroRelacaoDeSentido(texto){
		console.log(texto);
		this.listaDeRelacoesDeSentidoFiltrada = this.listaDeRelacoesDeSentido.filter((item) => {
			return item.relacaoDeSentido.toLowerCase().includes(texto.toLowerCase());
		});
	}
	
	selecionarRelacaoDeSentido(relacaoDeSentido){
		this.relacaoDeSentidoSelecionada = relacaoDeSentido;
		this.listaDeRelacoesDeSentidoFiltrada = this.listaDeRelacoesDeSentido;
	}
	
	calculaAltura(tipo){
		let altura = $('#card-busca').parent().parent().parent().height();
		// let altura = $(document).height() - 30 -
		// 			 $('body > app-root > app-admin-layout > div > div.main-panel.ps.ps--active-y > navbar-cmp > nav').outerHeight() -
		// 			 $('body > app-root > app-admin-layout > div > div.main-panel.ps.ps--active-y > div:nth-child(4) > footer-cmp > footer > div > p').outerHeight();
		return altura ? (tipo + ": " + altura + "px;") : "";
	}

	ngOnInit() {
	}
}
