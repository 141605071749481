import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { EditorModule } from "@tinymce/tinymce-angular";

import { AppRoutingModule } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';

import { AppComponent } from './app.component';

import { LayoutComponent } from './layouts/layout/layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PalavrasComponent } from './app/palavras/palavras.component';
import { RelacaoDeSentidoComponent } from './relacao-de-sentido/relacao-de-sentido.component';
import { ExerciciosComponent } from './exercicios/exercicios.component';
import { DiversidadeComponent } from './diversidade/diversidade.component';
import { SaibaMaisComponent } from './saiba-mais/saiba-mais.component';
import { DuvidasESugestoesComponent } from './duvidas-esugestoes/duvidas-esugestoes.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
	EditorModule,
    CommonModule,
    BrowserModule
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    AdminLayoutComponent,
    PalavrasComponent,
    RelacaoDeSentidoComponent,
    ExerciciosComponent,
    DiversidadeComponent,
    SaibaMaisComponent,
    DuvidasESugestoesComponent,
    LoginComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
