import { Component, OnInit } from '@angular/core';
import { Router, Route } from "@angular/router";

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    type: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/saiba-mais', 					title: 'Saiba Mais',  						icon:'pe-7s-search', 	 type: 'default', 	class: '' },
    { path: '/palavras', 					title: 'Palavras',  						icon:'pe-7s-comment', 	 type: 'default', 	class: '' },
    { path: '/relacao-de-sentido', 			title: 'Relação de Sentido',  				icon:'pe-7s-display2', 	 type: 'default', 	class: '' },
    { path: '/exercicios', 					title: 'Exercícios',  						icon:'pe-7s-note2', 	 type: 'default', 	class: '' },
    { path: '/diversidade', 				title: 'Diversidade de Elementos Coesivos', icon:'pe-7s-news-paper', type: 'default', 	class: 'ddec' },
    { path: '/duvidas-e-sugestoes', 		title: 'Dúvidas e Sugestões',  				icon:'pe-7s-mail', 		 type: 'default', 	class: '' },
	{ path: '/admin',	 					title: 'Administraçao', 					icon:'pe-7s-door-lock',  type: 'default', 	class: 'btn-li-bottom' },
	
	{ path: '/admin/saiba-mais', 			title: 'Saiba Mais',  						icon:'pe-7s-search', 	 type: 'admin',		class: '' },
    { path: '/admin/palavras', 				title: 'Palavras',  						icon:'pe-7s-comment', 	 type: 'admin',		class: '' },
    { path: '/admin/relacao-de-sentido', 	title: 'Relação de Sentido',  				icon:'pe-7s-display2', 	 type: 'admin',		class: '' },
    { path: '/admin/exercicios', 			title: 'Exercícios',  						icon:'pe-7s-note2', 	 type: 'admin',		class: '' },
    { path: '/admin/diversidade',	 		title: 'Diversidade de Elementos Coesivos', icon:'pe-7s-news-paper', type: 'admin',		class: 'ddec' },
    { path: '/admin/duvidas-e-sugestoes',	title: 'Dúvidas e Sugestões',  				icon:'pe-7s-mail', 		 type: 'admin',		class: '' },
	{ path: '/admin',	 					title: 'Sair',			 					icon:'pe-7s-back-2',	 type: 'admin',		class: 'btn-li-bottom' }
	
	// ,
    // { path: '/upgrade', title: '-------------------------',  icon:'pe-7s-door-lock', class: '' },
	// 
    // { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-comment', class: '' },
    // { path: '/user', title: 'Relação de Sentido',  icon:'pe-7s-display2', class: '' },
    // { path: '/table', title: 'Exercícios',  icon:'pe-7s-note2', class: '' },
    // { path: '/typography', title: 'Diversidade de elementos coesivos',  icon:'pe-7s-news-paper', class: 'ddec' },
    // { path: '/icons', title: 'Saiba Mais',  icon:'pe-7s-search', class: '' },
    // { path: '/maps', title: 'Dúvidas e Sugestões',  icon:'pe-7s-mail', class: '' },
    // { path: '/upgrade', title: 'Administraçao',  icon:'pe-7s-door-lock', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router) { }
  
  displayMenuItem(menuItem){
	  let isTypeAdmin = menuItem.type == 'admin';
	  let isPathAdmin = this.router.url.includes('/admin/');
	  return isTypeAdmin == isPathAdmin ? 'display: none;' : null;
	  // return (menuItem.type == 'admin') == router.url.includes('/admin/') ? 'display: none;' : null;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
