import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	constructor(private http: HttpClient) {
		// console.log(environment.token);
		environment.token = "";
	}
	
	onSubmitLogin(form){
		let formData:FormData = new FormData();
		formData.append('usuario', (document.getElementById('usuario') as HTMLInputElement).value);
		formData.append('senha', (document.getElementById('senha') as HTMLInputElement).value);

		this.http.post(environment.httpUrlLogin, formData).subscribe(
			result => this.callbackLogin(result),
			err => this.httpError(err)
		);

		swal({
			title: "",
			// html: '<div class="spinner-border spinner-border-sm text-success" role="status" style="font-size: 30px; height: 100px; width: 100px;"><span class="sr-only">Loading...</span></div>',
			html: '<div class="row justify-content-center align-items-center" style="height: 100px; margin-bottom: 15px;"><div class="col-auto"><h2 class="swal2-title">Entrando</h2><div id="loader" style="margin-top: 25px;"><img src="assets/img/loader-preview.svg" alt="loading"></div></div></div>',
			showCancelButton: false,
			showConfirmButton: false,
			allowOutsideClick: false,
			closeOnConfirm: true
		}).then(function(result){ });
	}
	
	callbackLogin(response){
		console.log(response);
		
		if(response.ok){
			swal.close();
			environment.token = response.dados.token;
			document.location.href = '#/admin/saiba-mais';
		}
		else{
			swal({
				title: "Erro",
				text: response.erro,
				showCancelButton: false,
				showConfirmButton: true,
				allowOutsideClick: false,
				closeOnConfirm: true
			}).then(function(result){ });
		}
		// this.listaDeRelacoesDeSentido = response;
		// this.listaDeRelacoesDeSentidoFiltrada = this.listaDeRelacoesDeSentido;
    }
	
	httpError(err){
		console.log('HttpError => ', err);
    }

	ngOnInit(): void {
	}
}
