import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

declare var tinymce: any;

@Component({
  selector: 'app-diversidade',
  templateUrl: './diversidade.component.html',
  styleUrls: ['./diversidade.component.css']
})
export class DiversidadeComponent implements OnInit {
	public wordCount = 0;
	public listaDePalavras = [];
	public listaDeRelacoes = [];
	public contagem = 0;

	constructor(private http: HttpClient){
		this.http.get(environment.httpUrlGetDiversidade, {}).subscribe(
            result => this.callback(result),
            err => this.httpError(err)
        );
	}
	
	callback(response){
		this.listaDePalavras = response;
		for(var index in this.listaDePalavras){
			if(!this.listaDeRelacoes.includes(this.listaDePalavras[index].relacaoDeSentidoNome)){
				this.listaDeRelacoes.push(this.listaDePalavras[index].relacaoDeSentidoNome);
			}
		}
    }
	
	httpError(err){
		console.log('HttpError => ', err);
    }
	
	calculaAltura(tipo){
		let altura = $('#card-busca').parent().parent().parent().height();
		return altura ? (tipo + ": " + altura + "px;") : "";
	}
	
	atualizarResultado(){
		$("#resultadoProgressBar").css("width", ((this.contagem*(100 / 30)) > 100 ? 100 : (this.contagem*(100 / 30))) + '%');
		
		if(this.contagem ==  0){
			$("#resultadoComentario").text("Não conseguimos identificar elementos coesivos em seu texto. Procure estudar sobre coesão textual. Ela é fundamental para que você faça uma boa produção textual.");
			$("#resultadoProgressBar").css("background-color", "#dc3545");
		}
		if(this.contagem >=  1 && this.contagem <=  7){
			$("#resultadoComentario").text("Identificamos pouquíssimos elementos coesivos em seu texto. Procure estudar sobre coesão textual e busque utilizar mais e diferentes elementos de coesão.");
			$("#resultadoProgressBar").css("background-color", "#dc3545");
		}
		if(this.contagem >=  8 && this.contagem <= 15){
			$("#resultadoComentario").text("Identificamos poucos elementos coesivos diferentes em seu texto. Procure diversificar mais! Invista no uso de elementos de coesão!");
			$("#resultadoProgressBar").css("background-color", "#fd7e14");
		}
		if(this.contagem >= 16 && this.contagem <= 23){
			$("#resultadoComentario").text("Identificamos uma quantidade boa de elementos coesivos diferentes em seu texto, mas aconselhamos que você use mais e diferentes elementos de coesão.");
			$("#resultadoProgressBar").css("background-color", "#ffc107");
		}
		if(this.contagem >= 24 && this.contagem <= 30){
			$("#resultadoComentario").text("Identificamos uma quantidade muito boa de elementos coesivos em seu texto! Estudando um pouquinho mais, você pode alcançar um desempenho excelente em termos de diversidade de elementos de coesão!");
			$("#resultadoProgressBar").css("background-color", "#28a745");
		}
		if(this.contagem  > 30){
			$("#resultadoComentario").text("Identificamos uma quantidade de elementos coesivos excelente! Parabéns! Continue assim! O uso diversificado de elementos de coesão é fundamental!");
			$("#resultadoProgressBar").css("background-color", "#28a745");
		}
	}
	
	codificarHtml(texto){
		var htmlCodigo = { 
					  'á' : {'code' : '&aacute;'},
					  'Á' : {'code' : '&Aacute;'},
					  'ã' : {'code' : '&atilde;'},
					  'Ã' : {'code' : '&Atilde;'},
					  'à' : {'code' : '&agrave;'},
					  'À' : {'code' : '&Agrave;'},                       
					  'é' : {'code' : '&eacute;'},
					  'É' : {'code' : '&Eacute;'},
					  'í' : {'code' : '&iacute;'},
					  'Í' : {'code' : '&Iacute;'},
					  'ó' : {'code' : '&oacute;'},
					  'Ó' : {'code ': '&Oacute;'}, 
					  'õ' : {'code' : '&otilde;'},
					  'Õ' : {'code' : '&Otilde;'},
					  'ú' : {'code' : '&uacute;'},
					  'Ú' : {'code' : '&Uacute;'},
					  'ç' : {'code' : '&ccedil;'},
					  'Ç' : {'code' : '&Ccedil;'}                      };
		var acentos = ['á', 'Á', 'ã', 'Ã', 'à', 'À', 'é', 'É', 'í', 'Í', 'ó', 'Ó', 'õ', 'Õ', 'ú', 'Ú', 'ç', 'Ç'];
		
		for(var i=0; i<acentos.length; i++){
			if(htmlCodigo [acentos[i]] != undefined){
				texto = texto.replaceAll(acentos[i], htmlCodigo[acentos[i]].code );
			}
		}
		
		return texto;
	}
	
	replaceDiversidade(texto){
		var antes = Date.now();
		var _this = this;
		var position, re, re_pre, re_pos;
		var codigo = 0;
		var substituicoes = [];
		var listaDePalavrasContagem = [];
		console.log("_this", _this);
		for(var index in _this.listaDePalavras){
			position = 0;
			// re_pre = _this.listaDePalavras[index].palavra[0].charCodeAt() < 192 ? "\\b" : "\\B";
			// re_pos = _this.listaDePalavras[index].palavra[_this.listaDePalavras[index].palavra.length-1].charCodeAt() < 192 ? "\\b" : "\\B";
			// re = new RegExp(re_pre+_this.listaDePalavras[index].palavra+re_pos,"igm");
			// re = new RegExp("(\\b|\\B)"+_this.listaDePalavras[index].palavra+"(\\b|\\B)","igm");
			re = new RegExp("(?<=[\\s,.:;>\"']|^)"+_this.listaDePalavras[index].palavra+"(?=[\\s,.:;(</p)\"']|$)","igm");
			while((position = texto.search(re)) != -1){
				//if(position-1 >= 0 && (texto[position-1] == " " || texto[position-1] == ">")){
					substituicoes.push({
						codigo: codigo,
						texto: texto.substring(position, position + _this.listaDePalavras[index].palavra.length)
					});
					
					if(!listaDePalavrasContagem.includes(_this.listaDePalavras[index].codigoPalavra)){
						listaDePalavrasContagem.push(_this.listaDePalavras[index].codigoPalavra);
					}
					
					texto = texto.substring(0, position) + "#"+codigo+"#" + texto.substring(position + _this.listaDePalavras[index].palavra.length);
					// texto = texto.replace(re, "#"+codigo+"#");
					codigo++;
				//}
			}
		}
		
		for(var index in substituicoes){
			// texto = texto.replace("#"+substituicoes[index].codigo+"#", '<strong class="destaque frequencia-1" title="'+substituicoes[index].relacaoDeSentidoNome+'">'+substituicoes[index].texto+'</strong>');
			texto = texto.replace("#"+substituicoes[index].codigo+"#", '<strong class="destaque frequencia-1">'+substituicoes[index].texto+'</strong>');
		}
		
		console.log("Duração:" + (Date.now() - antes) + " ms");
		console.log("Contagem:" + listaDePalavrasContagem.length);
		if(_this.contagem != listaDePalavrasContagem.length){
			_this.contagem = listaDePalavrasContagem.length;
			_this.atualizarResultado();
		}
		
		return texto;
	}

	ngOnInit(): void {
		
		console.log("this", this);
		
		let _this = this;
		tinymce.remove("#mensagem");
		tinymce.baseURL = '/assets/plugins/tinymce';
		tinymce.suffix = '.min';
		
		tinymce.init({
			selector: '#mensagem',
			plugins: "wordcount paste",
			paste_as_text: true,
			menubar: false,
			toolbar: false,
			statusbar: false,
			// statusbar: "wordcount"
			content_style: ".destaque { cursor: pointer; } html { background-color: #fff; background-image: linear-gradient(#abced4 0.1em, transparent 0.1em); background-size: 100% 1.4em; padding-top: 2px; } p { margin: 1.4rem 0rem; }",
			entity_encoding : "raw",
			setup: function (ed) {
				var allowedKeys = [8, 37, 38, 39, 40, 46]; // backspace, delete and cursor keys
				ed.on('keydown', function(e){
					var _wordCount = tinymce.activeEditor.plugins.wordcount.body.getWordCount();
					if(_wordCount < 500 || allowedKeys.indexOf(e.keyCode) != -1){
						return true;
					}
					else{
						return false;
					}
				});
			},
			init_instance_callback: function (editor) {
				// $(editor.getContainer()).find('button.tox-statusbar__wordcount').click();  // if you use jQuery
				
				var ctrlDown = false,
					ctrlKey = 17,
					cmdKey = 91,
					vKey = 86,
					cKey = 67;

				editor.on('keydown', function(e){
					if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = true;
				}).on('keyup', function(e){
					if (e.keyCode == ctrlKey || e.keyCode == cmdKey) ctrlDown = false;
				});
				
				
				console.log(_this);
				var charcount = document.getElementById("charcount");
				editor.on('keyup', function (e) {
					//if(true | e.wordCount.words != _this.wordCount){
					//	_this.wordCount = e.wordCount.words;
					//	console.log("_this.wordCount", _this.wordCount);
					// console.log("ctrlDown", ctrlDown);
					if(ctrlDown == false && (e.keyCode == 32 || e.keyCode == 8 || e.keyCode >= 48 && e.keyCode <= 90)){
						// console.log(tinymce.activeEditor.getContent());
						
						var endId = tinymce.DOM.uniqueId();
						var spanPosicaoAtual = '<span id="'+endId+'" class="posicaoAtual"></span>'
						tinymce.activeEditor.execCommand('mceInsertContent', false, spanPosicaoAtual);
						
						
						
						var texto = tinymce.activeEditor.getContent();
						
						// Esse bloco limpa o texto - Inicio
						// for(var index = 0; index < _this.listaDeRelacoes.length; index++){
						// 	texto = texto.replaceAll(' title="'+_this.codificarHtml(_this.listaDeRelacoes[index])+'"', "");
						// }
						texto = texto.replaceAll(/(<strong class="destaque frequencia-\d">|<\/strong>)/igm, "");
						// Esse bloco limpa o texto - Fim
						
						/*
						for(var index = 0; index < _this.listaDePalavras.length; index++){
							var re = new RegExp("\\b"+_this.listaDePalavras[index].palavra+"\\b","igm");
							texto = texto.replaceAll(re, '<strong class="destaque frequencia-1" title="'+_this.listaDePalavras[index].relacaoDeSentidoNome+'">'+_this.listaDePalavras[index].palavra+'</strong>');
						}
						/**/
						texto = _this.replaceDiversidade(texto);
						
						tinymce.activeEditor.setContent(texto);
						
						
						tinymce.activeEditor.selection.select($(tinymce.activeEditor.getBody()).find(".posicaoAtual")[0]);
						tinymce.activeEditor.selection.collapse(false);
						$(tinymce.activeEditor.getBody()).find(".posicaoAtual").remove();
						/**/
					}
					$(tinymce.activeEditor.getBody()).find(".destaque").on({
						mouseenter: function(e) {
							// (<any>$(tinymce.activeEditor.getBody()).find('.destaque').not(this)).popover('destroy');
							// var InstId = $(this).find("td:first-child").html();
							// var InstName = $(this).find("td:first-child").next("td").html();
							// if (!$(this).data("bs.popover")) {
							// 
							// 	(<any>$(this)).popover({
							// 		placement: 'right',
							// 		trigger: 'manual',
							// 		html: true,
							// 		title: InstName,
							// 		content: function () {
							// 			return "asdf";
							// 		}
							// 	});
							// 
							// }
							// 
							// (<any>$(this)).popover('show');
						},
						mouseleave: function(e) {
							//stuff to do on mouse leave
							console.log("mouseleave", e);
						}
					});
				});
			}
		});
		
		
		// tinymce.init({
		// 	placeholder: "Ask a question or post an update...",
		// 	theme_advanced_text_colors : "FF00FF,FFFF00,000000",
		// 	relative_urls : false,
		// 	remove_script_host : false,
		// 	selector: "textarea#mensagem",
		// 	theme: "modern",
		// 	plugins: "wordcount",
		// 	//height: 150,
		// 	menubar: false,
		// 	toolbar: false,
		// 	contextmenu: false,
		// 	// statusbar: 'wordcount',
		// 	style_formats: [
		// 		{title: 'Underline', inline: 'u'},
		// 		{title: 'Bold text', inline: 'b'},
		// 		{title: 'Red text', inline: 'span', styles: {color: '#ff0000'}},
		// 		{title: 'Red header', block: 'h1', styles: {color: '#ff0000'}},
		// 		{title: 'Example 1', inline: 'span', classes: 'example1'},
		// 		{title: 'Example 2', inline: 'span', classes: 'example2'},
		// 		{title: 'Table styles'},
		// 		{title: 'Table row 1', selector: 'tr', classes: 'tablerow1'}
		// 	],
		// 	noneditable_noneditable_class: 'mceNonEditable',
		// 	/*forced_root_block: "",*/
		// 	convert_urls: false,
		// 	setup: function(editor) {
		// 		// editor.on('init', function(e){
		// 		// 	console.log("init", e);
		// 		// });
		// 		// editor.on('change', function(e){
		// 		// 	console.log("change", e);
		// 		// });
		// 		editor.on('WordCountUpdate', function(e){
		// 			console.log("WordCountUpdate0", e);
		// 		});
		// 		editor.fire('WordCountUpdate', function(e){
		// 			console.log("WordCountUpdate1", e);
		// 		});
		// 	},
		// 	init_instance_callback: function (editor) {
		// 		$(editor.getContainer()).find('button.tox-statusbar__wordcount').click();  // if you use jQuery
		// 		
		// 		
		// 		
		// 		var charcount = document.getElementById("charcount");
		// 		editor.on('WordCountUpdate', function (e) {
		// 			charcount.innerHTML = e.wordCount.characters;
		// 			console.log("WordCountUpdate3", e);
		// 		});
		// 	}
		// });
		// 
		// 
		// tinymce.on('WordCountUpdate', function(e){
		// 	console.log("WordCountUpdate2", e);
		// });
		
		// function fixHeightOfTheText(){
		// 	var h = window.innerHeight;
		// 	var resizeHeight = h + "px";
		// 	var textElement = tinymce.DOM.get('area1_ifr');
		// 	tinymce.DOM.setStyle(textElement, 'height', resizeHeight);
		// }
		// window.onresize = fixHeightOfTheText;
	}
}
