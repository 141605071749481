// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

// window['urlBase'] = 'http://dev.elzin.xyz';
export const environment = {
	production: false,
	token: '',
	httpUrlGetPalavras: window['urlBase'] + '/getPalavras.php',
	httpUrlGetRelacoesDeSentido: window['urlBase'] + '/getRelacoesDeSentido.php',
	httpUrlGetExercicios: window['urlBase'] + '/getExercicios.php',
	httpUrlGetDiversidade: window['urlBase'] + '/getDiversidade.php',
	httpUrlLogin: window['urlBase'] + '/login.php'
};
