import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

declare var swal: any;

@Component({
  selector: 'app-exercicios',
  templateUrl: './exercicios.component.html',
  styleUrls: ['./exercicios.component.css']
})
export class ExerciciosComponent implements OnInit {
	public exercicioSelecionado = null;
    public listaDeExercicios = [];
	
	constructor(private http: HttpClient){
		this.http.get(environment.httpUrlGetExercicios, {}).subscribe(
            result => this.callback(result),
            err => this.httpError(err)
        );
	}
	
	callback(response){
		this.listaDeExercicios = response;
    }
	
	httpError(err){
		console.log('HttpError => ', err);
    }
	
	calculaAltura(tipo){
		let altura = $('#loader').parent().parent().parent().parent().height();
		return altura ? (tipo + ": " + altura + "px;") : "";
	}
	
	getClassRespostaSelecionada(alternativa){
		if(this.exercicioSelecionado.respostaSelecionada == "" || this.exercicioSelecionado.respostaSelecionada != alternativa){
			return "";
		}
		else{
			if(this.exercicioSelecionado.resposta == alternativa){
				return "respostaCorreta";
			}
			else{
				return "respostaIncorreta";
			}
		}
	}
	
	getClassIconeAlternativa(alternativa){
		if(this.exercicioSelecionado.respostaSelecionada == "" || this.exercicioSelecionado.respostaSelecionada != alternativa){
			return "fa-square-o";
		}
		else{
			if(this.exercicioSelecionado.resposta == alternativa){
				return "fa-check-square-o";
			}
			else{
				return "fa-times";
			}
		}
	}
	
	selecionarExercicio(tipo){
		for(let index = 0; index < this.listaDeExercicios.length; index++){
			if(this.listaDeExercicios[index] == this.exercicioSelecionado){
				this.exercicioSelecionado = this.listaDeExercicios[index+tipo];
				return true;
			}
		}
	}
	
	scrollTop(x){
		$('.main-panel').scrollTop(x);
	}
	
	//exibirFeedback(){
	//	setTimeout(() => {
	//		swal({
	//			title: "Feedback",
	//			html: "<div style='text-align: justify;'>" + this.exercicioSelecionado.feedback + "</div>",
	//			showCancelButton: false,
	//			confirmButtonClass: "btn-success",
	//			confirmButtonText: "Ok",
	//			closeOnConfirm: true
	//		}).then(function(result){ });
	//	}, 500);
	//}

	ngOnInit(): void {
	}
}
